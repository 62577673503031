import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import AmortizationSchedule from '../components/amortization-schedule'
import ChunkingSchedule from '../components/chunking-schedule'
import EquityAndInterestGraph from '../components/equity-and-interest-graph'

const VelocityBankingPage: React.FC = () => (
  <Layout>
    <SEO title="Velocity Banking" />
    <h1>Velocity Banking</h1>

    <p>
      Your mortgage is likely to be the biggest purchase of your life, velocity
      banking reduces the time it takes to pay down using existing cash flow.
    </p>

    <p>
      Velocity banking is a debt payment acceleration strategy using your
      existing cashflow to efficiently pay off your home loan in a shorter
      period of time. Aggressively using this strategy can allow you to pay off
      a standard 30 year mortgage in 5-7 years.
    </p>

    <p>
      It's most effective early in the life of the mortgage. Amortized loans
      have different mechanics to your standard simple interest loan. Velocity
      banking works by playing off the payment schedules of an amortized loan
      against the payment schedule of a simple interest loan. Lets use an
      example to make things more concrete.
    </p>

    <div>
      <AmortizationSchedule />
      <ChunkingSchedule />
      <EquityAndInterestGraph />
    </div>
  </Layout>
)

export default VelocityBankingPage
